import { getCurrencies } from '~/composables/queries/useCurrencies'
import type { Currency, CurrenciesCollection } from '~/src/types/Currency'

export async function useCurrency() {
  const { data: currenciesData, suspense: currenciesSuspense } = getCurrencies({})

  await currenciesSuspense()

  const currencies = computed<Currency[]>(() => {
    if (!currenciesData.value) { return [] }
    return (currenciesData.value as CurrenciesCollection)['hydra:member']
  })

  const fallback = computed<Currency>(() => {
    return currencies.value[0]
  })

  return { currencies, fallback }
}
